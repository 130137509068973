import React from 'react'
import { marked } from 'marked'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import withContext from '../../lib/withContext'

import DiscountDeal from '../../components/DiscountDeal'
import SEO from '../../components/SEO'

const LicensePage = ({
  data: {
    contentfulLicense: {
      intro: {
        intro
      },
      allowedTo: {
        allowedTo
      },
      notAllowedTo: {
        notAllowedTo
      },
      users: {
        users
      },
      refundPolicy: {
        refundPolicy
      },
      disclaimer: {
        disclaimer
      },
      reservedRights: {
        reservedRights
      },
      termination: {
        termination
      },
      questions: {
        questions
      }
    }
  },
  fullCollectionId,
  addVariantToCart,
  toggleModalShow,
  lineItems
}) => {
  const metaData = {
    title: 'License',
    image: undefined
  }

  return (
    <div>
      <SEO metaData={metaData} />
      <main className="license margin-xl-top animated fadeInPage">
        <div className="container-md">
          <article className="grid">
            <section className="col-8 margin-xl-bottom license-intro">
              <h1>License</h1>
              <p className="intro">{ intro }</p>
            </section>
            <section className="grid yg center">
              <section className="col-7 margin-m-bottom license-section">
                <h3>Allowed to</h3>
                <div className="list allowed" dangerouslySetInnerHTML={{ __html: marked(allowedTo) }} />
              </section>
              <section className="col-7 margin-m-bottom license-section">
                <h3>Not allowed to</h3>
                <div className="list not-allowed" dangerouslySetInnerHTML={{ __html: marked(notAllowedTo) }} />
              </section>
              <section className="col-7 margin-m-bottom license-section">
                <h3>Users</h3>
                <p>{ users }</p>
              </section>
              <section className="col-7 margin-m-bottom license-section">
                <h3>Refund policy</h3>
                <div dangerouslySetInnerHTML={{ __html: marked(refundPolicy) }} />
              </section>
              <section className="col-7 margin-m-bottom license-section">
                <h3>Disclaimer</h3>
                <p>{ disclaimer }</p>
              </section>
              <section className="col-7 margin-m-bottom license-section">
                <h3>Reserved Rights</h3>
                <p>{ reservedRights }</p>
              </section>
              <section className="col-7 margin-m-bottom license-section">
                <h3>Termination</h3>
                <div dangerouslySetInnerHTML={{ __html: marked(termination) }} />
              </section>
              <section className="col-7 margin-m-bottom license-section">
                <h3>Questions?</h3>
                <div dangerouslySetInnerHTML={{ __html: marked(questions) }} />
              </section>
            </section>
          </article>
        </div>

        <DiscountDeal
          fullCollectionId={fullCollectionId}
          addVariantToCart={addVariantToCart}
          type="large"
        />

      </main>
    </div>
  )
}

LicensePage.propTypes = {
  data: PropTypes.object,
  addVariantToCart: PropTypes.func,
  fullCollectionId: PropTypes.string,
  lineItems: PropTypes.array,
  toggleModalShow: PropTypes.func
}

export default withContext(LicensePage)

export const licensePageQuery = graphql`
  query licensePageQuery {
    contentfulLicense {
      intro {
        intro
      }
      allowedTo {
        allowedTo
      }
      notAllowedTo {
        notAllowedTo
      }
      users {
        users
      }
      refundPolicy {
        refundPolicy
      }
      disclaimer {
        disclaimer
      }
      reservedRights {
        reservedRights
      }
      termination {
        termination
      }
      questions {
        questions
      }
    }
  }
`

import React from 'react'
import PropTypes from 'prop-types'

// Language
import fullCollectionPrice from '../lang/fullCollectionPrice'

const {
  unit,
  fullPrice,
  discountedPrice
} = fullCollectionPrice

const DiscountDeal = ({
  isCenter,
  type,
  isBetweenProducts,
  fullCollectionId,
  addVariantToCart
}) => (
  <section
    className={`
      discount-deal
      ${type}
      ${isBetweenProducts ? 'between-products-container' : 'margin-xxl'}
      ${isCenter ? 'center' : ''}
    `}
  >
    <div className="container-lg positioning-container">
      <div
        className={`deal-container padding-l ${isCenter ? 'center' : ''}`}
        style={{
          backgroundImage: `url('${require(isCenter
            ? '../images/discount-deal/icons-center@2x.png'
            : '../images/discount-deal/icons@2x.png')}')`
        }}
      >

        <div className="backdrop" />

        <div className="yg grid">
          <div className="content right">
            <h2 className="margin-xxs-bottom">
              Download the complete Jollycons
            </h2>
            <p>
              {'Buy Jollycons to receive over 580+ custom designed icons perfect for any project.'}
            </p>
            <div>
              <button
                className="btn cta filled lg"
                type="button"
                onClick={() => addVariantToCart(fullCollectionId)}
              >
                <span>
                  Buy Now
                  <span className="discount">
                    {`${unit}${fullPrice}`}
                  </span>
                  <span className="price">
                    {`${unit}${discountedPrice}`}
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

DiscountDeal.defaultProps = {
  isBetweenProducts: true
}

DiscountDeal.propTypes = {
  addVariantToCart: PropTypes.func,
  fullCollectionId: PropTypes.string,
  isCenter: PropTypes.bool,
  isBetweenProducts: PropTypes.bool,
  type: PropTypes.oneOf([
    'large',
    'center'
  ])
}

export default DiscountDeal
